"use client";

import { FC, memo, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Keyboard } from 'swiper';
import { EffectCards } from 'swiper';

import styles from './OverlayGallery.component.module.css';

const OverlayGalleryComponent: FC = memo((props) => {
  const {
    images = [],
    title,
    size,
    itemsPerRow = 3,
    _uid,
    isActive,
    setIsActive,
    initialSlide,
    currentSlide,
  } = props;

  // store swiper instance
  const [swiper, setSwiper] = useState(null);
  const slideTo = (index) => swiper.slideTo(index);

  useEffect(() => {
    if (!swiper || currentSlide === null) return;
    // console.log('going to ', currentSlide + 1);
    slideTo(currentSlide);
  }, [currentSlide]);

  return (
    <div className={styles.wrapper} data-is-active={isActive} onClick={() => setIsActive(false)}>
      <div className={styles.close} onClick={() => setIsActive(false)}>
        <i className="fal fa-times" />
      </div>
      <span onClick={(e) => e.stopPropagation()}>
        <Swiper
          slidesPerView={'auto'}
          // width={1000}
          spaceBetween={10}
          // slidesPerView={3}
          centeredSlides={true}
          // effect={"cards"}
          loop={images.length > 1}
          modules={[EffectCards, Keyboard]}
          grabCursor={true}
          onSwiper={setSwiper}
          slideToClickedSlide={true}
          preventClicksPropagation={true}
          speed={200}
          keyboard
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}

          // navigation={true}
          // modules={[Keyboard, Navigation]}

          className={styles.swiper}
        >
          {images.map((image, index) => (
            <SwiperSlide key={image.filename} className={styles.swiperSlide}>
              {/* Slide {index} */}
              <img
                className={styles.image}
                src={`${image.filename}${size === 'raw' ? '/m/1500x0' : '/m/1500x1000'}`}
                alt={image?.alt}
                title={image?.title + ' ' + image?.name}
              />

              <figcaption className={styles.caption}>
                {image.name}
              </figcaption>

            </SwiperSlide>
          ))}
        </Swiper>
      </span>
    </div>
  );
});

OverlayGalleryComponent.displayName = 'OverlayGalleryComponent';

export { OverlayGalleryComponent };
