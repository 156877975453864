'use client';

import { useStoryblokBridge, registerStoryblokBridge } from '@storyblok/js';
import { useRouter } from 'next/navigation';

export const PageLogic = ({story}) => {
  const router = useRouter();

  registerStoryblokBridge(story.id, (newStory) => router.refresh());
  useStoryblokBridge(story?.id, (newStory) => router.refresh());

  return null;
};
