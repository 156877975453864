"use client";

import { FC, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { Img } from 'Components/Img/Img';

import styles from './ImageSlider.component.module.scss';

const ImageSliderComponent: FC = ({ images = [], title, _uid }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goNext = () => setCurrentIndex((i) => (i + 1 + images.length) % images.length);
  const goPrev = () => setCurrentIndex((i) => (i - 1 + images.length) % images.length);

  const handlers = useSwipeable({
    onSwipedLeft: goNext,
    onSwipedRight: goPrev,
    onTap: goNext,
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
  });

  if (!images.length) return null;

  return (
    <div
      className={styles.wrapper}
      key={_uid + '-imageSliderComponent'}
      data-key={_uid + '-imageSliderComponent'}
    >
      <div className="container">
        <div className={styles.title} hidden={!title}>
          {title}
        </div>
      </div>
      <div className={styles.images}>
        {images.map((image, index) => (
          <div
            key={image.filename + '-imageSlider'}
            className={styles.image}
            data-relative-position={index - currentIndex}
            data-position={index}
            style={
              {
                '--relativePosition': index - currentIndex,
                '--position': index,
              } as any
            }
          >
            <div className={styles.img} {...handlers}>
              <Img
                src={`${image?.filename}/m/2000x1000`}
                unoptimized
                priority={index === 0}
                layout="fill"
                alt={image.alt}
                title={image.title}
              />
            </div>
            <div className={styles.imageContent}>
              <div className={styles.imageTitle} hidden={!image.title}>
                {image.title}
              </div>
              <div className={styles.imageDescription} hidden={!image.name}>
                {image.name}
              </div>
            </div>
          </div>
        ))}

        <div className={styles.placeholder} />
      </div>

      <div className="container">
        <div className={styles.navContainer}>
        <div className={styles.nav} hidden={images.length < 2}>
          <div className={styles.navItem} onClick={goPrev}>
            <i className="fal fa-chevron-left" />
          </div>
          <div className={styles.navItem} onClick={goNext}>
            <i className="fal fa-chevron-right" />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export { ImageSliderComponent };
