/* eslint-disable @next/next/no-img-element */

'use client';

import { FC, useEffect, useRef, useState, memo, useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';
import sampleSize from 'lodash/sampleSize';
import shuffle from 'lodash/shuffle';
import chunk from 'lodash/chunk';

import { GlyphicsStory } from 'Entities/GlyphicsStory.entity';
import { PageStoryblok } from 'Entities/Storyblok';

import styles from './Splash.component.module.css';
import { filenameCreator } from 'Utils';
import { LogoSvg } from 'Svg/Logo';
import { hasShownHomepageAnimationAtom } from 'Atoms';
import Link from 'next/link';
import { LogoTitleSvg } from 'Svg/LogoTitle';
import { useWindowSize } from 'react-use';

const animatonTime = 0;
const dotCount = 1000;
const rows = 3;

const SplashComponent: FC<{ story: GlyphicsStory<PageStoryblok> }> = ({ story }) => {
  const [h, setH] = useState(Infinity);
  const [shuffledImages] = useState(shuffle(story.content.backgroundImages));
  const images: string[] = useMemo(() => shuffledImages.map((imageObj) => filenameCreator(imageObj, '500x0')), []);

  const splitImages = useMemo(() => chunk(images, Math.ceil(images.length / 2)), []);
  const imageRows = useMemo(() => chunk(splitImages[0], Math.ceil(splitImages[0].length / rows)), []);
  const imageRows2 = useMemo(() => chunk(splitImages[1], Math.ceil(splitImages[1].length / rows)), []);

  const scrollDown = () => {
    window.scrollTo({ top: window.innerHeight - 60, behavior: 'smooth' });
  };

  useEffect(() => {
    setH(window.innerHeight);
  }, []);

  return (
    <div
      className={styles.wrapper}
      data-is-started={true}
      data-is-done={true}
      style={{
        '--rows': rows,
        '--animatonTime': animatonTime,
        '--dotCount': dotCount,
        '--maxHeight': h === Infinity ? '100vh' : h + 'px',
      }}
    >
      <div className={styles.overlay} />
      <div className={styles.images} data-should-animate={true} data-is-done={true}>
        {imageRows.map((imageRow, imageRowIndex) => (
          <div className={styles.imageRow} key={imageRow[0]} style={{ '--imageCount': imageRow.length * 2 }}>
            {imageRow.map((image, imageIndex) => (
              <RandomSplashImage images={[image, imageRows2[imageRowIndex][imageIndex]]} key={image + imageIndex} />
            ))}
            {imageRow.map((image, imageIndex) => (
              <RandomSplashImage
                images={[image, imageRows2[imageRowIndex][imageIndex]]}
                key={'z' + image + imageIndex + '_2'}
              />
            ))}
          </div>
        ))}
      </div>

      <div className={styles.logo} data-is-active={true} data-is-done={true}>
        <LogoSvg />
      </div>

      <LogoTitleSvg className={styles.title} />

      <div className={styles.links}>
        <Link href="/our-signs" className={styles.link} prefetch={false}>
          Find a sign
        </Link>
        <Link href="/get-in-touch" className={styles.link} prefetch={false}>
          Get a quote
        </Link>
      </div>

      <div className={styles.scrollDown} onClick={scrollDown}>
        {story.content.heroTitle}
        {/* scroll down for more */}
        <i className="fal fa-angle-down" />
      </div>
    </div>
  );
};

const RandomSplashImage: FC<{ images: string[] }> = memo(({ images }) => {
  const [interval] = useState<number>(Math.random() * 10000);

  return (
    <div className={styles.randomImage} style={{ '--interval': interval }}>
      {images.map((image, index) => (
        <img src={image} alt="" key={index} loading="lazy" />
      ))}
    </div>
  );
});

RandomSplashImage.displayName = 'RandomSplashImage';

export { SplashComponent };
