'use client';

import { FC } from 'react';

import styles from './Iframe.module.css';

type IframeComponentProps = {
  url: string;
  title: string;
  height: string;
};

export const IframeComponent: FC<IframeComponentProps> = (props) => {
  const { url, title, height } = props;

  return (
    <>
      <iframe className={styles.iframe} src={url} title={title} height={height} width="100%" />
    </>
  );
};
