/* eslint-disable @next/next/no-img-element */

'use client';

import { PanelsComponentStoryblok, PanelStoryblok } from 'Entities/Storyblok';
import { FC, memo, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

import { render } from 'storyblok-rich-text-react-renderer';
import { filenameCreator } from 'Utils';

import styles from './Panels.component.module.css';

const PanelsComponent: FC<PanelsComponentStoryblok> = memo((props) => {
  const { panels, _uid } = props;

  console.log('PanelsComponent', props);

  return (
    <div className="container">
      <div className={styles.wrapper}>
        {panels.map((panel) => (
          <PanelComponent key={panel._uid} panel={panel} />
        ))}
      </div>
    </div>
  );
});

const PanelComponent: FC<{ panel: PanelStoryblok }> = memo(({ panel }) => {
  const { type, width, backgroundColor, image, text, statLabel } = panel;
  const statValue = panel.statValue ? Number(panel.statValue) : undefined;

  const [$ref, isInView] = useInView();
  const [open, toggle] = useState(false);
  const props = useSpring({ config: { tension: 50, mass: 2 }, to: { statValue: isInView ? statValue : 0 } });

  return (
    <div
      ref={$ref}
      className={styles.panel}
      data-type={type}
      onClick={() => toggle(!open)}
      style={{
        '--width': width,
        '--backgroundColor': backgroundColor,
      }}
    >
      <img className={styles.image} alt={image.alt} src={filenameCreator(image, '1000x0')} />

      <div className={styles.statLabel}>{statLabel}</div>

      {statValue && <animated.div className={styles.statValue}>{props.statValue.to((x) => x.toFixed(0))}</animated.div>}

      {text && <div className={styles.text}>{render(text)}</div>}
    </div>
  );
});

PanelsComponent.displayName = 'PanelsComponent';
PanelComponent.displayName = 'PanelComponent';

export { PanelsComponent };
