'use client';

import { OverlayGalleryComponent } from 'Components/OverlayGallery/OverlayGallery.component';
import { FC, useEffect, useState } from 'react';

import styles from './ImageGallery.component.module.css';

const asCustomProp = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result['--' + key] = obj[key];
  });
  return result as any;
};

export const ImageGalleryComponent: FC = (props) => {
  const { images = [], title, size, itemsPerRow = 3, _uid, inline } = props;

  const [isActive, setIsActive] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) setIsActive(false);
    };

    window.addEventListener('keydown', handleEsc);

    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  return (
    <div className={styles.wrapper} key={_uid} data-is-inline={inline}>
      <OverlayGalleryComponent {...props} isActive={isActive} setIsActive={setIsActive} currentSlide={currentSlide} />

      <div className="container">
        <div className={styles.title} hidden={!title}>
          {title}
        </div>
      </div>

      <div className="container">
        <div className={styles.layout}>
          {inline && <div className={styles.padder} />}
          <div className={styles.mosaic} data-size={size} data-is-inline={inline} style={asCustomProp({ itemsPerRow })}>
            {images.map((image, index) => (
              <div key={image.filename + '-imageGallery'} className={styles.mosaicImage}>
                <img
                  onClick={() => {
                    setIsActive(true);
                    setCurrentSlide(index);
                    setTimeout(() => {
                      setCurrentSlide(null);
                    }, 10);
                  }}
                  src={`${image?.filename}${size === 'raw' || size === 'manual' ? '/m/1500x0' : '/m/1500x1000'}`}
                  alt={image?.alt}
                  datz-size={size}
                  title={image?.title + ' ' + image?.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
