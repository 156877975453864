'use client';

import Head from 'next/head';
import { useAtomValue } from 'jotai/utils';
import upperFirst from 'lodash/upperFirst';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import { FC, Fragment } from 'react';
import { useInterval } from 'react-use';

import styles from './Breadcrumbs.component.module.css';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface BreadcrumbsComponentProps {
  story?: any;
  variant?: string;
}

const BreadcrumbsComponent: FC<BreadcrumbsComponentProps> = ({ story, variant }) => {
  // const router = useRouter();

  //console.log({ router });
  const slugs = (story?.full_slug ?? '')
    .split('/')
    .filter(Boolean)
    .reduce((acc, slug) => {
      return [...acc, `${acc.join('/')}/${slug}`];
    }, []);

  // console.log({ slugs });

  const breadcrumbJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: slugs.map((slug, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: slug.split('/').reverse()[0].split('-').map(upperFirst).join(' '),
      item: slug,
    })),
  };

  if (story.full_slug.includes('thank-you')) return null;

  return (
    <>
      <script
        key={'breadcrumb-ld'}
        data-key={'breadcrumb-ld'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }}
      />
      <div className="container">
        <div className={styles.wrapper} data-variant={variant}>
          <Link passHref href={'/'} prefetch={false}>
            Home
          </Link>
          {' / '}
          {slugs.map((slug, index) => (
            <Fragment key={slug}>
              <Link passHref href={slug} prefetch={false}>
                {slug.split('/').reverse()[0].split('-').map(upperFirst).join(' ')}
              </Link>
              {index === slugs.length - 1 ? '' : ' / '}
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export { BreadcrumbsComponent };
