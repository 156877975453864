import React, { RefObject, MutableRefObject, FC, SVGProps } from 'react';

const LogoTitleSvg: FC<
  {
    ref?: MutableRefObject<SVGSVGElement>;
    pathRef?: MutableRefObject<SVGPathElement>;
  } & SVGProps<SVGSVGElement>
> = ({ ref, pathRef, ...props }) => (
  <>
    <svg
      ref={ref}
      className="glypics-logo-text"
      viewBox="0 0 222 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        ref={pathRef}
        d="M43.1 0.100006H47.2V37.8C47.2 39.9 48.2 40.9 50.2 40.9C50.5 40.9 50.8 40.9 51.1 40.8C51.4 40.7 51.6 40.7 51.7 40.7V44.2C51.4 44.3 51.1 44.4 50.8 44.4C50.3 44.5 49.5 44.6 48.7 44.6C47 44.6 45.6 44.1 44.6 43C43.6 41.9 43.1 40.4 43.1 38.4V0.100006Z"
      />
      <path d="M57.9 56.7C56.9 56.7 56.1 56.6 55.5 56.5C55.2 56.4 54.8 56.4 54.6 56.3L55 52.6C55.3 52.7 55.5 52.7 55.8 52.8C56 52.8 56.3 52.9 56.6 52.9C56.9 52.9 57.3 52.9 57.6 52.9C58.4 52.9 59 52.7 59.7 52.4C60.4 52.1 61 51.6 61.6 50.8C62.2 50.1 62.9 49 63.5 47.8C64.1 46.5 64.8 44.9 65.5 43.1L54.3 12.7H58.8L67.5 37.1H67.8L76.5 12.7H81L69.8 43.1C68.9 45.6 67.9 47.7 67 49.5C66.1 51.3 65.1 52.7 64.1 53.7C63.1 54.7 62.1 55.6 61 56C60.2 56.4 59.1 56.7 57.9 56.7Z" />
      <path d="M161.3 43.9H157.2V19.3C157.2 17.2 156.3 16.2 154.4 16.2C154.1 16.2 153.6 16.2 152.8 16.4V12.8C153.3 12.7 154.1 12.5 154.6 12.4C154.9 12.4 155.4 12.3 155.8 12.3C157.5 12.3 158.9 12.8 159.9 13.9C160.9 15 161.4 16.5 161.4 18.5L161.3 43.9ZM156.3 2.90001C156.3 2.00001 156.6 1.3 157.1 0.800003C157.6 0.300003 158.4 0 159.2 0C160 0 160.8 0.300003 161.3 0.800003C161.8 1.3 162.1 2.00001 162.1 2.90001C162.1 3.80001 161.8 4.5 161.3 5C160.8 5.5 160 5.8 159.2 5.8C158.4 5.8 157.6 5.5 157.1 5C156.6 4.5 156.3 3.80001 156.3 2.90001Z" />
      <path d="M190.7 38C189.9 38.7 189 39.3 187.9 39.8C186.8 40.3 185.5 40.6 183.9 40.6C181 40.6 178.6 39.6 176.8 37.6C175 35.6 174 32.5 174 28.4C174 26.4 174.2 24.6 174.7 23C175.2 21.5 175.8 20.2 176.7 19.2C177.6 18.2 178.6 17.4 179.7 16.9C180.8 16.4 182.1 16.1 183.5 16.1C185.8 16.1 187.6 16.7 188.8 17.9C190.1 19.1 190.7 20.6 190.7 22.4C190.7 22.8 190.7 23.2 190.7 23.6H194.8C194.8 23.2 194.8 22.6 194.8 22.2C194.8 20.9 194.5 19.6 194 18.4C193.5 17.2 192.7 16.1 191.7 15.2C190.7 14.3 189.5 13.6 188.1 13.1C186.7 12.6 185.1 12.3 183.4 12.3C181.4 12.3 179.6 12.7 177.9 13.4C176.2 14.1 174.8 15.2 173.6 16.5C172.4 17.9 171.5 19.6 170.8 21.5C170.1 23.4 169.8 25.8 169.8 28.4C169.8 31 170.1 33.3 170.8 35.3C171.5 37.3 172.4 38.9 173.6 40.3C174.8 41.7 176.2 42.7 177.9 43.4C179.6 44.1 181.4 44.5 183.4 44.5C185.5 44.5 187.3 44.2 188.8 43.5C190.3 42.8 191.5 42.1 192.4 41.4C193.5 40.5 194.4 39.4 195.1 38.3L192.7 35.4C192.3 36.4 191.6 37.3 190.7 38Z" />
      <path d="M217.2 28.4C215.9 27.7 214.5 27.2 213 26.8C211.5 26.4 210.1 25.9 208.8 25.5C207.5 25 206.5 24.5 205.6 23.9C204.8 23.3 204.3 22.4 204.3 21.3C204.3 20 204.9 18.9 206 17.9C207.1 16.9 208.7 16.4 210.9 16.4C213.1 16.4 214.6 16.9 215.7 17.8C216.8 18.7 217.4 20 217.4 21.5C217.4 21.7 217.4 21.9 217.4 22.1H221.4C221.4 21.9 221.4 21.6 221.4 21.3C221.4 20.1 221.2 18.9 220.7 17.9C220.2 16.8 219.5 15.9 218.6 15.1C217.7 14.3 216.6 13.7 215.3 13.2C214 12.7 212.5 12.5 210.9 12.5C209.2 12.5 207.7 12.7 206.4 13.2C205.1 13.7 203.9 14.3 203 15.1C202.1 15.9 201.4 16.8 200.9 17.9C200.4 19 200.2 20.1 200.2 21.3C200.2 23.1 200.6 24.5 201.5 25.6C202.3 26.7 203.4 27.6 204.7 28.2C206 28.9 207.4 29.4 208.9 29.8C210.4 30.2 211.8 30.7 213.1 31.1C214.4 31.6 215.4 32.1 216.3 32.7C217.1 33.3 217.6 34.2 217.6 35.3C217.6 36.8 217 38.1 215.9 39.1C214.8 40.1 213.1 40.6 210.8 40.6C209.2 40.6 207.8 40.3 206.6 39.8C205.4 39.3 204.5 38.6 203.7 38C202.8 37.2 202 36.4 201.4 35.4L199 38.3C199.8 39.5 200.8 40.5 201.9 41.4C202.9 42.2 204.1 42.9 205.6 43.5C207.1 44.1 208.9 44.5 211 44.5C212.7 44.5 214.2 44.3 215.5 43.8C216.8 43.3 218 42.7 218.9 41.8C219.8 41 220.6 40 221.1 38.9C221.6 37.8 221.8 36.6 221.8 35.3C221.8 33.5 221.4 32.1 220.5 31C219.5 30 218.4 29.1 217.2 28.4Z" />
      <path d="M18.7 23.1V26.9H29.7V35.5C29 36.5 28.2 37.4 27.2 38.1C26.3 38.8 25.2 39.4 23.8 39.9C22.4 40.4 20.7 40.7 18.8 40.7C16.7 40.7 14.8 40.3 13 39.6C11.2 38.9 9.8 37.8 8.5 36.3C7.2 34.8 6.3 33 5.6 30.8C4.9 28.6 4.5 26 4.5 23C4.5 20 4.8 17.4 5.5 15.2C6.2 13 7.2 11.1 8.4 9.7C9.6 8.3 11.1 7.20001 12.8 6.40001C14.5 5.70001 16.3 5.3 18.3 5.3C20 5.3 21.5 5.50001 22.7 5.90001C28.4 7.90001 29.4 11.8 29.3 13.4H33.5C33.5 13.3 33.5 13.3 33.5 13.2C33.5 6.8 27.2 3.30001 24.3 2.40001C22.5 1.80001 20.5 1.5 18.2 1.5C15.5 1.5 13.1 2.00001 10.9 2.90001C8.7 3.80001 6.8 5.2 5.2 7C3.6 8.8 2.4 11 1.4 13.7C0.500002 16.4 0 19.5 0 23C0 26.5 0.500002 29.6 1.4 32.3C2.3 35 3.6 37.2 5.2 39C6.8 40.8 8.7 42.1 11 43.1C13.2 44 15.7 44.5 18.4 44.5C21.1 44.5 23.4 44.1 25.3 43.3C27.2 42.5 28.8 41.7 30.1 40.7C31.6 39.6 32.9 38.3 33.9 36.9V23L18.7 23.1Z" />
      <path d="M112.9 21.5C112.2 19.5 111.4 17.8 110.3 16.5C109.2 15.2 108 14.1 106.6 13.4C105.2 12.7 103.7 12.4 102.2 12.4C100.4 12.4 98.8 12.7 97.6 13.3C96.4 13.9 95.4 14.6 94.6 15.3C93.7 16.2 93.1 17.1 92.5 18.2H92.2C92.2 17.4 92 16.8 91.7 16.1C91.4 15.4 91 14.9 90.6 14.4C90.2 13.9 89.6 13.5 88.9 13.2C88.2 12.9 87.6 12.8 86.8 12.8C86.4 12.8 86 12.8 85.6 12.9C85.1 13 84.1 13.2 83.8 13.3V16.6C84.4 16.5 85 16.4 85.3 16.4C87.3 16.4 88.3 17.5 88.3 19.5V56.7H92.4V27.6C92.4 25.8 92.6 24.3 93.1 22.9C93.6 21.5 94.3 20.3 95.1 19.4C95.9 18.4 96.9 17.7 98 17.2C99.1 16.7 100.2 16.4 101.4 16.4C102.5 16.4 103.5 16.6 104.5 17.1C105.5 17.6 106.3 18.3 107.1 19.4C107.9 20.4 108.4 21.7 108.9 23.2C109.4 24.7 109.6 26.5 109.6 28.6C109.6 30.6 109.3 32.4 108.8 33.9C108.3 35.4 107.6 36.7 106.7 37.7C105.8 38.7 104.8 39.5 103.7 40C102.6 40.5 101.3 40.8 100 40.8C98.9 40.8 98 40.7 97 40.5C96.3 40.3 95.7 40.1 95.2 40V44C95.8 44.2 96.4 44.3 97.1 44.5C98.1 44.7 99.1 44.8 100.3 44.8C102.2 44.8 104 44.4 105.7 43.7C107.4 43 108.8 41.9 110 40.6C111.2 39.2 112.2 37.6 112.9 35.6C113.6 33.6 113.9 31.4 113.9 28.8C113.9 25.9 113.5 23.5 112.9 21.5Z" />
      <path d="M126.2 0.100006H122.1V44H126.2V0.100006Z" />
      <path d="M145.5 19.2C145 17.8 144.3 16.7 143.4 15.7C142.5 14.7 141.4 14 140.2 13.5C138.9 13 137.6 12.7 136.1 12.7C134.3 12.7 132.8 13 131.6 13.6C130.6 14.2 129.7 14.7 129 15.4V19.5C129.8 18.5 130.8 17.8 131.9 17.3C133 16.8 134.2 16.5 135.4 16.5C137.4 16.5 139.1 17.2 140.3 18.6C141.5 20 142.1 21.8 142.1 24V43.9H146.2V23.8C146.3 22.1 146 20.6 145.5 19.2Z" />
    </svg>
  </>
);

export { LogoTitleSvg };
