'use client';

import { FC, useState } from 'react';
import Image from 'next/legacy/image';

import styles from './Multi.component.module.scss';
import Link from 'next/link';

const MultiComponent: FC = ({ title, items }) => {
  //console.log('MultiComponent', { title, items });

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="container" data-is-spaced>
      <div className={styles.title} hidden={!title}>
        {title}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.nav}>
          {items.map((item, index) => (
            <div
              className={styles.navItem}
              key={item.title}
              data-is-active={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className={styles.content}>
          {items.map((item, index) => (
            <div className={styles.images} key={item.title} data-is-active={index === currentIndex}>
              {item.images.map((image) => (
                <div className={styles.image} key={image.filename}>
                  <Image
                    src={`${image?.filename}/m/2000x1000`}
                    alt={image.alt}
                    title={image.title}
                    layout="fill"
                    unoptimized
                  />
                </div>
              ))}

              {item.link && (item.link?.cached_url || item.link?.url) && (
                <Link
                  href={item.link?.cached_url ? `/${item.link.cached_url}` : `/${item.link?.url}`}
                  className={styles.link}
                  prefetch={false}
                >
                  {item.title} <i className="fal fa-chevron-right" />
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { MultiComponent };
