'use client';

import Image from 'next/legacy/image';
import Link from 'next/link';
import { FC, useState, memo } from 'react';
import { useInterval } from 'react-use';
import random from 'lodash/random';
import ReactGA from 'react-ga4';
import { StoryData } from 'storyblok-js-client';

import { Img } from 'Components/Img/Img';

import styles from './Promo.component.module.css';

const PromoComponent: FC<{ page: StoryData; variant?: string }> = memo(({ page, variant }) => {
  const [count, setCount] = useState(0);
  const { title, backgroundImages = [] } = page?.content ?? {};

  useInterval(() => {
    setCount((count) => (count + 1) % backgroundImages.length);
  }, random(3000, 15000));

  const handleClick = () => {
    ReactGA.event({
      category: 'Interactions',
      action: 'Promo click',
      label: title, // optional
    });
  };

  if (!page) return null;

  return (
    <Link prefetch={false} passHref href={'/' + page?.full_slug} key={title} className={styles.wrapper}>
      <div className={styles.overlay} />
      <div className={styles.images}>
        {backgroundImages.map((image, index) => (
          <div data-index={index} className={styles.image} data-is-active={count === index} key={image?.filename}>
            <Img src={`${image?.filename}/m/1000x0`} alt={image.alt} layout="fill" unoptimized />
          </div>
        ))}
      </div>
      <div className={styles.title}>{page?.name}</div>
    </Link>
  );
});

PromoComponent.displayName = 'PromoComponent';

export { PromoComponent };
