'use client';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import kebabCase from 'lodash/kebabCase';

import { render } from 'storyblok-rich-text-react-renderer';
import { Storyblok } from 'Utils/Storyblok.util';
import styles from './Faqs.component.module.css';
import { usePathname } from 'next/navigation';

const FaqsComponent: FC = ({ faqs, title }) => {
  const faqsJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      faqs.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: Storyblok.richTextResolver.render(faq.content),
        },
      })),
    ],
  };

  return (
    <>
      <script
        key={'breadcrumb-ld'}
        data-key={'breadcrumb-ld'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(faqsJsonLd) }}
      />
      <div className={styles.wrapper} id={kebabCase(title)}>
        <div className="container">
          <h2 className={styles.title} hidden={!title}>
            {title}
          </h2>

          <div className={styles.layout}>
            {/* <div className={styles.padder} /> */}
            <dl className={styles.faqs}>
              {faqs.map((faq, index) => (
                <FaqComponent {...faq} key={faq._uid} />
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

const FaqComponent: FC = ({ question, content }) => {
  const asPath = usePathname();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen((isOpen) => isOpen || asPath?.includes(kebabCase(question)));
  }, [asPath]);

  return (
    <div className={styles.faq} data-is-open={isOpen} id={kebabCase(question)}>
      <h3 className={styles.question} onClick={() => setIsOpen(!isOpen)}>
        {question}
      </h3>

      <i className="fal fa-angle-up" />

      <dd className={styles.text}>{render(content)}</dd>
    </div>
  );
};

export { FaqsComponent };
